import { Link } from 'gatsby'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <section style={{ padding: '20px 40px', width: '100%', maxWidth: 800, margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Link to="/" className={'privacyPolicyButton'}>Back to homepage</Link><br />
      <p>
        I. OPENING STATEMENTS<br /><br />
      1. Designingui.com website is ran by HYPE4Mobile Malewicz i
      Wspólnicy S.J. - 81-749 Sopot ul Ksiazat Pomorskich 12/15.KRS:
      0000719418, NIP: 5851483018 , REGON: 369513506,<br />
      2. Before using the website or signing up, please read the terms and
      conditions thoroughly.<br />
      3. This terms document outlines all the rules guiding this app and general
      law of the EU.<br />
      4. Using the website implies that the user has read and accepted the terms
      and conditions and has agreed to it fully.<br />
      5. The rules of this document are guaranteed by consumer rights and based
      on the currently available laws regarding those rights.<br />
      6. Consumer contact data:<br />
      a. E-mail: hello@designingui.com<br /><br />
      I. DEFINITIONS<br /><br />
      1. Website – a computer program running on an iOS device (iPhone or
      iPad) that can be viewed online.<br />
      2. Administrator - The company that is administering over the databases:
      HYPE4Mobile Malewicz i Wspólnicy S.J. - 81-749 Sopot ul Ksiazat
      Pomorskich 12/15.KRS: 0000719418, NIP: 5851483018 , REGON:
      369513506<br />
      3. Terms - This document with all attachments and additions<br />
      4. User - Everyone who has used our contact form<br />
      5. Registration form – The website has a sign up form that allows the user
      to create a personalised quote.<br />
      6. Email - Electronic mailbox with an individual (identifiable) internet
      address of the user.<br />
      I. Email signup<br />
      1. Signing up for our newsletter and to receive the free chapters of the
      book is completely voluntary.<br />
      2. By signing up you confirm that:<br />
      a. Has read, understood and accepted these terms,<br />
      b. Is not violating any of the terms of this agreement.<br />
      3. The user can REMOVE the email address at any time. Account<br />
      removal results in the loss of ALL data that was stored. You can
      simply click on the Unsubscribe button in the footer of any email we’ve sent. In one business day after removal your email address will
      also be completely purged from our databases.
      II. 5. The administrator can remove a users account manually IF:<br />
      a. The administrator suspects the user is violating the terms and
      conditions and/or using the website improperly in a way that can cause
      harm to the service,<br />
      b. Or if the user didn't accept the new terms and conditions if they are
      ever modified,<br />
      c. If the user never logged in or hasn't log in in over 12 months from
      the creation date of the email address ,<br />
      d. If the user won't activate or verify the email address within 30 days,<br />
      e. If when reminded the user hasn't stopped the behaviour that was
      violating the terms and conditions or refused to do so.<br />
      6. The content and information the user is adding to the website during
      registration can be moderated by the administrator if the content is
      violating the terms and conditions.<br /><br />
      I. RESPONSIBILITY<br /><br />
      1. For the good and safety and to provide the highest possible service
      quality it's prohibited to use the website or any of it's functionalities in a
      way that was not intended by the website maker, especially but not limited
      to actions that are prohibited by law, good practices, social norms and this
      agreement.<br />
      2. The admin will try to provide the website service without interruptions,
      but cannot be held responsible for technical issues, random occurences or
      by actions of the users that are not following this agreement. The
      administrator will try to keep the uptime of the website at at least 90%
      yearly.<br />
      3. Administrators will also add new security measures when they're
      available, that can consist but not limited to encryption, data security and
      password requirements.<br />
      4. Administrators can anonymously publish content directed to the quality
      assurance team by the users that can be then condensed into a FAQ to
      answer similar questions in the future.<br />
      5. Administrator can enable advertising within the website for the website's
      future growth if necessary and by accepting these terms the user is
      acknowleding this. I. Intellectual property rights<br />
      1. All Intellactual property rights to the website, especially to the content,
      word and brandmarks, names, images, drawings and functionalities belong
      to the administrator. Using the website in no way allows for the users to
      own any of the above mentioned rights to the intellectual property.<br />
      2. It is prohibited (unless a written permission is given by the
      administrator) to copy, modify and use the intellectual property of the
      Designingui.com brand and app. This excludes having the designingui.com
      app files on the users device, as it's necessary for the proper usage of the
      product and is in line with the terms outlined here.<br /><br />
      I. TERMS ADJUSTMENTS<br /><br />
      1. The terms can be modified ONLY if an important change is required. In
      these cases the users are presented with the new terms each time a change
      is being made. Without agreeing to them the users can request removal of
      the email address at any moment.<br />
      2. Administrators are publishing terms and conditions updates on the
      website website (designingui.com). The terms will not take effect less
      than 14 days before the change is announced.<br />
      3. Terms changes have no effect on actions taken before the changes take
      place.<br />
      4. Elements not requiring terms change can include promotions, contests,
      additional features and paid partnerships. The rules for any of these
      elements will be outlined in separate attachments added to this terms
      document.<br /><br />
      I. FINAL STATEMENTS<br /><br />
      1. The terms and conditions document is provided to the user free of
      charge on the website website and can be printed, saved and downloaded
      from the website website (designingui.com)<br />
      2. If a court rules any of the points of this agreement as invalid, that
      doesn't automatically invalidate the rest of the document.<br />
      3. All cases that are not outlined in this agreement are governed by the EU
      laws.<br />
      4. All cases related to the functioning of the website and services provided
      in the website will be decided by the court of the Republic of Poland.<br />
      5. All questions, inquiries and suggestions can be directed to:<br />
      hello@designingui.com
      <br /><br />PRIVACY POLICY - Designingui.com<br /><br />
      Respecting your right to privacy designingui.com is doing it's best to
      protect your personal data. This privacy policy outlines how we collect,
      store, secure and use your data.
      By using the designingui.com app you agree to the rules and conditions
      outlined in this privacy policy. Who is administering your personal data ?
      HYPE4Mobile Malewicz i Wspólnicy S.J. - 81-749 Sopot ul Ksiazat
      Pomorskich
      12/15.KRS: 0000719418, NIP: 5851483018 , REGON: 369513506<br /><br />
      What data are we collecting?<br /><br />
      To use the website you DON'T have to create an account or disclose your
      email. In that case we collect absolutely NO data about you. If you register
      we are collecting the following data:<br />
      1. First and last name (optional)<br />
      2. Your email address<br />
      If you sign up to our newsletter or forums we only collect your email
      address. By signing up and logging in you are agreeing to process your
      personal data for promotional and/or marketing reasons. The usage of the
      website doesn't require registering. A complete registration is synonymous
      to agreeing for us to process your personal data as outlined in this
      document.<br /><br />
      How are we using your data?<br /><br />
      Collected data are only used for purposes that you agreed to, like
      contacting with you in case of technical difficulties, setting your
      preferences for the product.<br />
      Every week we also send a weekly newsletter with design tips, book
      information and useful links.<br />
      What are your rights regarding your personal data processing?<br />
      You have the ability to acces, modify, removing your personal data. You
      can also ask for us to cease the procesing (which is the same as removing
      the email address).<br /><br />
      Security<br /><br />
      We are using all technical and organisational ways to provide security of
      your data and protect them from both accidental and purposeful
      destruction, modification, breach. The data is stored on servers with high
      security measures (encryption and multi-level verification) that comply to
      the current laws regarding data security. We will work on updating the
      security features whenever a new technology allowing this is tested and
      verified.<br />
      Remember to NEVER disclose publicly any of your personal data used in
      the website. Disclosure of that kind is your own responsibility and
      designingui.com cannot be held responsible for it.
      <br /><br />Data reveal to certain
      parties<br /><br />
      Law enforcement can request some (or all) of your personal data based on
      a lawful basis provided. In any other not law required case your personal
      data will not be disclosed without your consent. Privacy policy changes
      The offer, services, technologies, standards and law requirements are often
      changing. Becuase of that we may update this privacy policy to reflect
      those changes and we will notify you about them 14 days before they take
      effect. Using the website after the change confirms the agreement to the
      change. Our contact information<br />
      1. Designingui.com website is ran by HYPE4Mobile Malewicz i
      Wspólnicy S.J. - 81-749 Sopot ul Ksiazat Pomorskich 12/15.KRS:
      0000719418, NIP: 5851483018 , REGON: 369513506,<br />
      2. +48 781 405 748<br />
      3. hello@hype4.com<br />
      </p>
    </section>
  )
}

export default PrivacyPolicy